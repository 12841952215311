<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.timer.name') }}
        <el-checkbox :value="withTimer" @change="changeWithTimer"></el-checkbox>
      </div>
    </div>
    <timer-editor
      v-if="withTimer"
      :timer="timer"
      :is-not-interrupting="('isInterrupting' in attributes) ? !attributes['isInterrupting'] : false"
      @change_is_not_interrupting="changeInterrupting"
    >
    </timer-editor>
  </div>
</template>

<script>
import mixins from '../mixins'
import TimerEditor from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/Shared/TimerEditor/index.vue'
import TimerUtils from '@/services/BPMNEditor/infrastructure/service/TimerUtils'

export default {
  name: 'BoundaryEventEditor',
  components: { TimerEditor },
  inject: ['getModeler'],
  mixins: [mixins],
  data () {
    return {
      withTimer: false,
      timer: undefined
    }
  },
  mounted () {
    this.timer = TimerUtils.getTimer(this.element)
    this.withTimer = !!this.timer
  },
  methods: {
    changeInterrupting (value) {
      this.$emit('change-attribute', {
        name: 'isInterrupting',
        value: !value
      })
    },
    changeWithTimer (value) {
      TimerUtils.changeTimer(this.getModeler(), this.element, value)
      this.timer = TimerUtils.getTimer(this.element)
      this.withTimer = value
    }
  }
}
</script>
